<template>
    <div id="HistoricoPedidos">
        <div class="container">
            <div class="row">
                <div v-if="$store.getters.screen_size.width <= 767" class="col-md-9 col-sm-12 col-12">
                    <div class="row">
                        <div class="col-12 div-top">
                            <button class="btn-voltar" @click="voltaHomeCardapio()">
                                <v-icon>fas fa-chevron-left</v-icon>
                            </button>
                            <div class="div-usuario">
                                <div class="infos">
                                    <span class="span_bem_vindo">
                                        Bem-vindo (a)
                                    </span>
                                    <span class="nome">
                                        {{cliente.nome}}
                                    </span>
                                </div>
                                <div class="icone">
                                    <img src="@/assets/images/REDIRECTUSUARIO.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 titulo-pagina">
                            <span>Histórico de Pedidos</span>
                        </div>
                        <div class="col-12 div-historico-pedidos" v-if="$store.getters.screen_size.width <= 767">
                            <template>
                                <v-card>
                                    <v-tabs v-model="tab" background-color="transparent" grow>
                                        <v-tab v-for="item in items" :key="item">
                                            {{ item }}
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="tab">
                                        <v-tab-item>
                                            <v-card v-for="pedido in pedidos" :key="pedido.identificador">
                                                <v-list v-if="pedido.situacao != 'Pedido Finalizado' && pedido.situacao != 'Pedido Reprovado'" :data="contador_pendentes = 1">
                                                    <v-list-group no-action>
                                                        <template v-slot:activator>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="'#' + pedido.identificador + ' - ' + formataData(pedido.data_cadastro)">
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                        <v-card-text>
                                                            <div class="data-horario-pedido">
                                                                <div class="data-pedido">
                                                                    <b>Data:</b> 
                                                                    <span>
                                                                        {{formataData(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="horario-pedido">
                                                                    <b>Horário:</b>
                                                                    <span>
                                                                        {{formataHorario(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="status-pedido">
                                                                    <b>Status:</b> 
                                                                    <span>
                                                                        {{pedido.situacao}}
                                                                        <router-link :to="'meus-pedidos/' + pedido.identificador">
                                                                            <v-icon>fas fa-eye</v-icon>
                                                                        </router-link>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="itens-pedido">     
                                                                <b>Itens:</b>                                               
                                                                {{pedido.itens}}
                                                            </div> -->
                                                            <div class="pagamento">
                                                                <div class="metodo">                                                    
                                                                    <b>Método de Pagamento:</b> 
                                                                    <span>
                                                                        {{pedido.pagamento}}
                                                                    </span>
                                                                </div>
                                                                <div class="valor-pedido">                                                    
                                                                    <b>Total:</b> 
                                                                    <span>
                                                                        {{formataValor(pedido.valor)}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </v-card-text>
                                                    </v-list-group>
                                                </v-list>
                                            </v-card>
                                            <v-card-text v-if="contador_pendentes == 0">
                                                <p class="mensagem-aviso">
                                                    Não há pedidos pendentes.
                                                </p>
                                            </v-card-text>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-card v-for="pedido in pedidos" :key="pedido.identificador">
                                                <v-list v-if="pedido.situacao == 'Pedido Finalizado' || pedido.situacao == 'Pedido Reprovado'" :data="contador_historico = 1">
                                                    <v-list-group no-action>
                                                        <template v-slot:activator>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="'#' + pedido.identificador + ' - ' + formataData(pedido.data_cadastro)"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                        <v-card-text>
                                                            <div class="data-horario-pedido">
                                                                <div class="data-pedido">
                                                                    <b>Data:</b> 
                                                                    <span>
                                                                        {{formataData(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="horario-pedido">
                                                                    <b>Horário:</b>
                                                                    <span>
                                                                        {{formataHorario(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="status-pedido">
                                                                    <b>Status:</b> 
                                                                    <span>
                                                                        {{pedido.situacao}}
                                                                        <router-link :to="'meus-pedidos/' + pedido.identificador">
                                                                            <v-icon>fas fa-eye</v-icon>
                                                                        </router-link>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="itens-pedido">     
                                                                <b>Itens:</b>                                               
                                                                {{pedido.itens}}
                                                            </div> -->
                                                            <div class="pagamento">
                                                                <div class="metodo">                                                    
                                                                    <b>Método de Pagamento:</b> 
                                                                    <span>
                                                                        {{pedido.pagamento}}
                                                                    </span>
                                                                </div>
                                                                <div class="valor-pedido">                                                    
                                                                    <b>Total:</b> 
                                                                    <span>
                                                                        {{formataValor(pedido.valor)}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </v-card-text>
                                                    </v-list-group>
                                                </v-list>
                                            </v-card>
                                            <v-card-text
                                                v-if="contador_historico == 0"
                                            >
                                                <p class="mensagem-aviso">
                                                    Não há pedidos em seu histórico.
                                                </p>
                                            </v-card-text>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                            </template>
                        </div>                       
                    </div>
                </div>
                <div v-else class="col-md-9 col-sm-9 col-11">
                    <div class="row">
                        <div class="col-12 div-top">
                            <div class="div-usuario">
                                <figure id="logo-historico">
                                    <img class="img-responsive" alt="Logo" src="@/assets/images/historico.png">
                                </figure>
                                <div class="infos">
                                    <span class="span_bem_vindo">
                                        Bem vindo (a)
                                    </span>
                                    <span class="nome">
                                        {{cliente.nome}}
                                    </span>
                                </div>
                            </div>
                            <div class="wrapper-btns">
                                <router-link :to="'/' + this.$route.params.url_amigavel + '/'">
                                    <v-icon>fas fa-times</v-icon>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-12 titulo-pagina">
                            <span>Histórico de Pedidos</span>
                        </div>
                        <div class="col-12 div-historico-pedidos">
                            <template>
                                <v-card>
                                    <v-tabs v-model="tab" background-color="transparent" grow>
                                        <v-tab v-for="item in items" :key="item">
                                            {{ item }}
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="tab">
                                        <v-tab-item>
                                            <v-card v-for="pedido in pedidos" :key="pedido.identificador">
                                                <v-list v-if="pedido.situacao != 'Pedido Finalizado' && pedido.situacao != 'Pedido Reprovado'" :data="contador_pendentes = 1">
                                                    <v-list-group no-action>
                                                        <template v-slot:activator>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="'Pedido #' + pedido.identificador + ' - ' + formataDataExtenso(pedido.data_cadastro)">
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                        <v-card-text>
                                                           <div class="data-horario-pedido">
                                                                <div class="data-pedido">
                                                                    <b>Data:</b> 
                                                                    <span>
                                                                        {{formataData(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="horario-pedido">
                                                                    <b>Horário:</b>
                                                                    <span>
                                                                        {{formataHorario(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="status-pedido">
                                                                    <b>Status:</b> 
                                                                    <span>
                                                                        {{pedido.situacao}}
                                                                        <router-link :to="'meus-pedidos/' + pedido.identificador">
                                                                            <v-icon>fas fa-eye</v-icon>
                                                                        </router-link>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="itens-pedido">     
                                                                <b>Itens:</b>                                               
                                                                {{pedido.itens}}
                                                            </div> -->
                                                            <div class="pagamento">
                                                                <div class="metodo">                                                    
                                                                    <b>Método de Pagamento:</b> 
                                                                    <span>
                                                                        {{pedido.pagamento}}
                                                                    </span>
                                                                </div>
                                                                <div class="valor-pedido">                                                    
                                                                    <b>Total:</b> 
                                                                    <span>
                                                                        {{formataValor(pedido.valor)}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </v-card-text>
                                                    </v-list-group>
                                                </v-list>
                                            </v-card>
                                            <v-card-text v-if="contador_pendentes == 0">
                                                <p class="mensagem-aviso">
                                                    Não há pedidos pendentes.
                                                </p>
                                            </v-card-text>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-card v-for="pedido in pedidos" :key="pedido.identificador">
                                                <v-list v-if="pedido.situacao == 'Pedido Finalizado' || pedido.situacao == 'Pedido Reprovado'" :data="contador_historico = 1">
                                                    <v-list-group no-action>
                                                        <template v-slot:activator>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="'Pedido #' + pedido.identificador + ' - ' + formataDataExtenso(pedido.data_cadastro)"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                        <v-card-text>
                                                            <div class="data-horario-pedido">
                                                                <div class="data-pedido">
                                                                    <b>Data:</b> 
                                                                    <span>
                                                                        {{formataData(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="horario-pedido">
                                                                    <b>Horário:</b>
                                                                    <span>
                                                                        {{formataHorario(pedido.data_cadastro)}}
                                                                    </span>
                                                                </div>
                                                                <div class="status-pedido">
                                                                    <b>Status:</b> 
                                                                    <span>
                                                                        {{pedido.situacao}}
                                                                        <router-link :to="'meus-pedidos/' + pedido.identificador">
                                                                            <v-icon>fas fa-eye</v-icon>
                                                                        </router-link>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="itens-pedido">     
                                                                <b>Itens:</b>                                               
                                                                {{pedido.itens}}
                                                            </div> -->
                                                            <div class="pagamento">
                                                                <div class="metodo">                                                    
                                                                    <b>Método de Pagamento:</b> 
                                                                    <span>
                                                                        {{pedido.pagamento}}
                                                                    </span>
                                                                </div>
                                                                <div class="valor-pedido">                                                    
                                                                    <b>Total:</b> 
                                                                    <span>
                                                                        {{formataValor(pedido.valor)}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </v-card-text>
                                                    </v-list-group>
                                                </v-list>
                                            </v-card>
                                            <v-card-text v-if="contador_historico == 0">
                                                <p class="mensagem-aviso">
                                                    Não há pedidos em seu histórico.
                                                </p>
                                            </v-card-text>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card>
                            </template>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script>
    import store from '@/store'
    import IconeUsuario from '@/assets/images/REDIRECTUSUARIO.png'
    import IconeSair from '@/assets/images/SAIR.png'
    import IconeHome from '@/assets/images/HOMEICON.png'
    import Loader from '@/components/Loader.vue'

    export default {
        name: 'HistoricoPedidos',
        components: {
			Loader,
		},
        data: () => ({
            icone_Usuario: IconeUsuario,
            icone_Sair: IconeSair,
            icone_Home: IconeHome,
            loading: false,
            contador_pendentes: 0,
            contador_historico: 0,
            cliente: {
                codigo: '',
                nome: '',
            },
            tab: null,
            items: [
                'Pendentes', 'Finalizados',
            ],
            pedidos: []
        }),
        methods: {
            async init(){
                this.cliente.codigo = store.getters.clientes.usuario.id
                this.cliente.nome = store.getters.clientes.usuario.nome
                this.pedidos = []
                let pedidos_temp = await store.dispatch('getMeusPedidos');
                if(pedidos_temp.dados_pedido.length > 0){
                    this.pedidos = pedidos_temp.dados_pedido
                }
            },
            voltaHomeCardapio(){
                this.$router.push('/' + this.$route.params.url_amigavel)
            },
            formataData(String){
                let Dia = String.substr(8,2)
                let Mes = String.substr(5,2)
                let Ano = String.substr(0,4)
                let DataFormatada = Dia+'/'+Mes+'/'+Ano
                return DataFormatada
            },
            formataDataExtenso(String){
                const moment = require('moment')
                let data_formatada = moment(String)
                data_formatada = new Date(data_formatada)
                
                // Day part from the timestamp
                var dia = data_formatada.getDate();
                // Month part from the timestamp
                var mes = data_formatada.getMonth();
                // Year part from the timestamp
                var ano = data_formatada.getFullYear();
                // Hours part from the timestamp
                const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
        
                data_formatada = dia + ' de ' + meses[mes] + ' de ' + ano

                return data_formatada
            },
            formataHorario(String){
                // -3 por motivo do fuso horário
                let Hora = String.substr(11,2) - 3
                let Minuto = String.substr(14,2)
                let HorarioFormatado = Hora+':'+Minuto
                return HorarioFormatado
            },
            replaceAll(campo,search,replace){
                return campo.replace(new RegExp('[' + search + ']', 'g'), replace); 
            },
            formataValor(String){

                let valorFormatado = parseFloat(String)
                valorFormatado = valorFormatado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

                return valorFormatado
            }
        },
        async mounted(){
            await this.init()
        }
    }
</script>

<style lang="scss">
    #HistoricoPedidos{
        position: relative;
        .div-top{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .div-usuario{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                svg{
                    font-size: 30px;
                    path{
                        fill: #fe8204;
                    }
                }
                .infos{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    padding-left: 23px;
                    margin-right: 10px;
                    span{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        &.span_bem_vindo{
                            font-weight: 700;
                        }
                        &.localizacao{
                            font-family: 'JosefinSans';
                            font-size: 18;
                        }
                    }
                }
            }
            .wrapper-btns{
                display: flex;
                align-items: center;
                button{
                    display: flex;
                    padding-right: 20px;
                    img{
                        width: 32px;
                    }
                }
                svg{
                    font-size: 25px;
                    margin-left: 10px;
                    background-color: transparent;
                    width: 40px;
                    height: 40px;
                    padding: 8px;
                    border-radius: 30px;
                    transition: all ease .3s;
                    path{
                        fill: $primarycolor;
                    }
                    &:hover{
                        background-color: #f3f3f3;
                    }
                    &:active{
                        background-color: #dadada;
                    }
                }
            }
            .btn-voltar{
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
                align-items: center;
				width: max-content;
				svg{
					path{
						fill: #FE8204;
					}
				}
			}
        }
        .titulo-pagina{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            span{
                font-family: 'Poppins';
                font-size: 25px;
                font-weight: 600;
                text-align: center;
                width: 100%;
            }
        }
        .div-historico-pedidos{
            font-family: 'Poppins';
            .data-horario-pedido{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                font-size: 16px;
                font-weight: 500;
                // @media (max-width: 767px) {
                    justify-content: initial;
                    div{
                        display: flex;
                        width: 100%;
                        span{
                            width: 100%;
                            font-weight: 300;
                            display: flex;
                            justify-content: space-between;
                            text-align: left;
                        }
                    }
                // }
                .data-pedido, .horario-pedido, .status-pedido{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 0px 10px 5px;
                    // @media (max-width: 767px) {
                        justify-content: initial;
                    // }
                    b{
                        font-weight: 700;
                        margin-right: 5px;
                    }
                }
                .status-pedido{
                    span{
                        justify-content: flex-start;
                    }
                    svg{
                        margin-left: 5px;
                        path{
                            fill: #FE8204;
                        }
                    }
                }
            }
            .itens-pedido{
                padding: 0 15px;
            }
            .pagamento{
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                b{
                    font-weight: 700;
                }
                .valor-pedido{
                    font-weight: 700;
                    font-size: 20px;
                }
                // @media (max-width: 767px) {
                    text-align: left;
                    div{
                        padding: 0px 10px 5px;
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        span{
                            width: 100%;
                            font-weight: 300;
                        }
                    }
                // }
            }
            .mensagem-aviso{
                font-size: 18px;
                font-weight: 700;                
            }
            .n-pedido{
                font-size: 20px;
                font-weight: 400;
                color: #FE8204;
            }
            .v-tabs{
                margin-bottom: 15px;
            }
            .v-tab{
                font-weight: 600;
                &.v-tab--active{
                    color: #FE6B15;
                    font-weight: 700;
                }
            }
            .v-card{
                color: #404040;
                box-shadow: none;
                .v-list-item{
                    border-bottom: 1px solid #404040;
                    .v-list-item__title{
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
                .v-card__text{
                    color: #404040;
                    @media (max-width: 767px) {
                        padding: 0;
                    }
                }
            }
        }
        &:before{
            content: '';
            position: fixed;
            top: 0;
            right: 0;
            background-position: center;
            width: 25%;
            height: 100vh;
            z-index: 1;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('~@/assets/images/sideEditUsuario.png');
            @media (max-width: 767px){
                display: none;
            }
        }
    }
</style>